import HomePage from "./page/users/homePage";
import { ROUTERS } from "./utils/router";
import { Route, Routes } from "react-router-dom"
import React from "react"
import MasterLayout from "./page/users/theme/masterLayout";
import ProfilePage from "./page/users/profile";

const renderUserRouter = () => {
    const userRouter = [
        {
            path: ROUTERS.USER.Home,
            component: <HomePage />,
        },
        {
            path: ROUTERS.USER.Profile,
            component: <ProfilePage />,
        },
        {
            path: ROUTERS.USER.SanPham,
            component: <ProfilePage />,
        },
    ];

    return (
        <MasterLayout>
            <Routes>
                {userRouter.map((item, key) => (
                    <Route key={key} path={item.path} element={item.component} />
                ))}
            </Routes>
        </MasterLayout>
    );
};

const RouterCustom = () => {
    return renderUserRouter();
}

export default RouterCustom;