import React, { memo, useState } from "react";
import { Link } from "react-router-dom"
import './style.scss';
import {AiOutlineShoppingCart, AiOutlineMenu, AiOutlineFacebook, AiOutlineInstagram, AiOutlineDownCircle, AiOutlineUpCircle } from "react-icons/ai";
import {MdEmail, MdPhoneInTalk} from "react-icons/md"
import { formatter } from "utils/formater";
import { ROUTERS } from "utils/router";
import logo from './logo_page.png';

const Header = () => {

    const [isShowCategories, setShowCategories] = useState(false);
    const [isShowSmallMenu, setSmallMenu] = useState(false);

    const [menus, setMenu] = useState([
        {
            name: "Trang Chủ",
            path: ROUTERS.USER.Home
        },
        {
            name: "Sản Phẩm",
            path: ROUTERS.USER.Home,
            isShowSubMenu: false,
            child: [
                {
                    name: "San Pham 1",
                    path: ""
                },
                {
                    name: "San Pham 2",
                    path: ""
                },
                {
                    name: "San Pham 3",
                    path: ""
                },
                {
                    name: "San Pham 4",
                    path: ""
                },
                {
                    name: "San Pham 5",
                    path: ""
                }
            ]
        },
        {
            name: "Liên Hệ",
            path: ROUTERS.USER.Profile
        }
    ])

    return (
        <>
            <div className={`small__screen__overplay${isShowSmallMenu ? " active" : ""}`} onClick={() => setSmallMenu(false)} />
            <div className={`small__screen__wrapper${isShowSmallMenu ? " show" : ""}`} >
                <div className="logo"><img src={logo} alt='logo'></img></div>
                <div className="small__screen__cart">
                    <ul>
                        <li>
                            <Link to={""}> <AiOutlineShoppingCart /> <span>1</span> </Link>
                        </li>
                    </ul>
                    <div className="header__cart__price">
                        Giỏ Hàng: <span>{formatter(1020000)}</span>
                    </div>
                </div>
                <div className="small__screen__nav">
                    <ul>
                        {menus.map((menu, menuKey) => (
                            <li key={menuKey} >
                                <Link to={menu.path} onClick={() => {
                                    const newMenus = [...menus];
                                    newMenus[menuKey].isShowSubMenu = !newMenus[menuKey].isShowSubMenu;
                                    setMenu(newMenus);
                                }}>{menu.name}
                                    {menu.child && (menu.isShowSubMenu ? 
                                    (<AiOutlineDownCircle />) : (<AiOutlineUpCircle />)
                                    )}
                                </Link>
                                {menu.child && (
                                    <ul className={`header__menu__drop ${ menu.isShowSubMenu ? "showSubMenu" : ""}` }>
                                        {menu.child.map((childItem, childKey) => (
                                            <li key={`${menuKey}-${childKey}`}>
                                                <Link to={childItem.path}>{childItem.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="header__top__right__social">
                    <Link to={""}>
                        <AiOutlineFacebook />
                    </Link>
                    <Link to={""}>
                        <AiOutlineInstagram />
                    </Link>
                </div>
                <div className="small__screen__contact">
                    <ul>
                        <li>
                             <MdEmail /> maniafigureshop@gmail.com
                        </li>
                        <li>
                            <MdPhoneInTalk />0373 xxx xxx
                        </li>
                    </ul>
                </div>
            </div>
            <div className="header__main">
                <div className="cointaner">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                            <div className="header__logo">
                                <img src={logo} alt='logo'></img>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <nav className="header__menu">
                                <ul>
                                    {
                                        menus?.map((menu, menuKey) => (
                                            <li key={menuKey} className={menuKey === 0 ? "active" : ""}>
                                                <Link to={menu?.path}> {menu?.name} </Link>
                                                {
                                                    menu.child && (
                                                        <ul className="header__menu__dropdown">
                                                            {
                                                                menu.child.map((childItem, chilKey) => (
                                                                    <li key={`${menuKey}-${chilKey}`}>
                                                                        <Link to={childItem.path}>{childItem.name}</Link>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    )
                                                }
                                            </li>
                                        ))
                                    }
                                </ul>
                            </nav>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                            <div className="header__cart">
                                <div className="header__cart__price">
                                    <span>{formatter(1999999)}</span>
                                </div>
                                <ul>
                                    <li>
                                        <Link to="">
                                            <AiOutlineShoppingCart /> <span>5</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="list__small_screen">
                                <AiOutlineMenu onClick={() => setSmallMenu(true)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cointaner">
                <div className="row hero__categories_cointaner">
                    <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 hero__categories">
                        <div className="row hero__categories_all" onClick={() => setShowCategories(!isShowCategories)}> <AiOutlineMenu /><p>Danh Sách Sản Phẩm</p></div>
                        <ul className={isShowCategories ? "show" : ""}>
                            <li><Link to="#">San Pham 1</Link></li>
                            <li><Link to="#">San Pham 2</Link></li>
                            <li><Link to="#">San Pham 3</Link></li>
                            <li><Link to="#">San Pham 4</Link></li>
                            <li><Link to="#">San Pham 5</Link></li>
                        </ul>

                    </div>
                    <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 hero__search_cointaner">
                        <div className="hero__search">
                            <form>
                                <input type="text" placeholder="Bạn đang tìm gì?"></input>
                                <button className="btn-sbm">Tìm Kiếm</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(Header); 