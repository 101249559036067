import React, { memo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./style.scss";
import feaImg1 from "assest/users/images/featured/f1.jpg";
import feaImg2 from "assest/users/images/featured/f2.jpg";
import feaImg3 from "assest/users/images/featured/f3.jpg";
import feaImg4 from "assest/users/images/featured/f4.jpg";
import feaImg5 from "assest/users/images/featured/f5.jpg";
import feaImg6 from "assest/users/images/featured/f6.jpg";
import feaImg7 from "assest/users/images/featured/f9.jpg";
import feaImg8 from "assest/users/images/featured/f8.jpg";
import {  TabPanel, Tabs } from "react-tabs";
import { AiOutlineEye, AiOutlineShoppingCart } from "react-icons/ai";
import { formatter } from "utils/formater";
import { Link } from "react-router-dom";
const HomePage = () => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };



    const featproduct = {
        all: {
            title: "Toàn Bộ",
            products: [
                {
                    img: feaImg1,
                    name: "item_1",
                    price: 200000
                },
                {
                    img: feaImg2,
                    name: "item_2",
                    price: 220000
                },
                {
                    img: feaImg3,
                    name: "item_3",
                    price: 225000
                },
                {
                    img: feaImg4,
                    name: "item_4",
                    price: 140000
                },
                {
                    img: feaImg5,
                    name: "item_5",
                    price: 500000
                },
                {
                    img: feaImg6,
                    name: "item_6",
                    price: 528640
                },
                {
                    img: feaImg7,
                    name: "item_7",
                    price: 999000
                },
                {
                    img: feaImg8,
                    name: "item_8",
                    price: 553700
                }
            ],
        },
        figure1: {
            products: [
                {
                    img: feaImg1,
                    name: "item_1",
                    price: 20000
                },
                {
                    img: feaImg5,
                    name: "item_5",
                    price: 500000
                },
            ],
        },
        figure2: {
            products: [
                {
                    img: feaImg3,
                    name: "item_3",
                    price: 225000
                },
                {
                    img: feaImg8,
                    name: "item_8",
                    price: 553700
                }
            ],
        },
        figure3: {
            products: [
                {
                    img: feaImg2,
                    name: "item_2",
                    price: 220000
                },
                {
                    img: feaImg4,
                    name: "item_4",
                    price: 140000
                },
            ],
        },
    };

    const renderFeaturedProducts = (data) => {
        const tabPanels = [];

        Object.keys(data).forEach((key) => {
            const tabPanel = [];
            data[key].products.forEach((item, j) => {
                tabPanel.push(
                    <div key={j} className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="featured__item">
                            <div className="featured__item_pic" style={{backgroundImage: `url(${item.img})`}}>
                                <ul className="featured__item__pic__hover">
                                    <li>
                                        <AiOutlineEye />
                                    </li>
                                    <li>
                                        <AiOutlineShoppingCart />
                                    </li>
                                </ul>
                            </div>
                            <div className="featured__item_text">
                                <h6>
                                    <Link to="#">{item.name}</Link>
                                </h6>
                                <h5>{formatter(item.price)}</h5>
                            </div>
                        </div>
                    </div>
                )
            })
            tabPanels.push(tabPanel)
        })
        return (
            <Tabs>
                {tabPanels.map((item, key) => (
                    <TabPanel key={key}>
                        <div className="row">{item}</div>
                    </TabPanel>
                ))}
            </Tabs>
        );
    }

    return (
        <>
            {/*Carousel Slider start*/}
            <div className="cointaner cointaner__caterogies_slider">
                <Carousel responsive={responsive} className="cointaner__caterogies" >
                    <div className="cointaner__caterogies_item" style={{ backgroundImage: `url(${feaImg1})` }}><p>Mũi Đại Lãnh</p></div>
                    <div className="cointaner__caterogies_item" style={{ backgroundImage: `url(${feaImg2})` }}><p>SunSet</p></div>
                    <div className="cointaner__caterogies_item" style={{ backgroundImage: `url(${feaImg3})` }}><p>Kem Bơ</p></div>
                    <div className="cointaner__caterogies_item" style={{ backgroundImage: `url(${feaImg4})` }}><p>Nem Nướng</p></div>
                    <div className="cointaner__caterogies_item" style={{ backgroundImage: `url(${feaImg5})` }}><p>Tháp Đinh Phong</p></div>
                    <div className="cointaner__caterogies_item" style={{ backgroundImage: `url(${feaImg6})` }}><p>Biển</p></div>
                </Carousel>
            </div>
            {/*Carousel Slider end*/}

            {/*Featured Begin*/}

            <div className="cointaner">
                <div className="featured">
                    <div className="section-title">
                        <h2>Sản Phẩm Nổi Bật</h2>
                    </div>
                    {renderFeaturedProducts(featproduct)}
                </div>
            </div>
            {/*Featured End*/}
        </>
    );
};

export default memo(HomePage); 