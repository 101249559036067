import React, { memo } from "react";
import './style.scss';
import { AiOutlineFacebook, AiOutlineInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="cointaner">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                            <div className="footer__about">
                                <h1 className="footer__about__logo">ManiaFigure Shop</h1>
                                <ul>
                                    <li>
                                        Địa chỉ: 216 34/04 - Ninh Kiều - Cần Thơ
                                    </li>
                                    <li>
                                        Số điện Thoại: 0xxx-xxx-xxx
                                    </li>
                                    <li>
                                        Email: maniafigureshop@gmail.com
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                            <div className="footer__widget">
                                <h6>Khuyến mãi và ưu đãi</h6>
                                <p> Đăng kỳ nhận thông tin tại đây</p>
                                <form action="#">
                                    <div>
                                        <input type="email" placeholder="Nhập Email"/>
                                        <button type="submit" className="btn-sbm">
                                            Đăng Ký
                                        </button>
                                    </div>
                                    <div className="footer__widget__social">
                                        <div>
                                            <Link to=""><AiOutlineFacebook /></Link>
                                            
                                        </div>
                                        <div>
                                            <Link to=""><AiOutlineInstagram /></Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default memo(Footer); 